<template>
  <header class="bg-white fixed top-0 w-full shadow-md z-50">
    <div class="mx-auto">
      <nav class="container flex items-center justify-between h-11 xl:h-14">
        <!-- Logo -->
        <div class="flex-none">
          <a :href=homeUrl class="flex items-center">
            <img src="/build/images/logo.svg" alt="Leakblock Logo" class="h-[17px] w-auto"/>
          </a>
        </div>

        <!-- Desktop Navigation - Centered -->
        <div class="hidden xl:flex items-center justify-center relative 2xl:absolute 2xl:left-1/2 2xl:-translate-x-1/2 flex-1 2xl:flex-none">
          <a :href="homeUrl" class="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.home') }}
          </a>
          <a :href="contentProtectionUrl" class="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.contentProtection') }}
          </a>
          <a :href="howItWorksUrl" class="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.howItWorks') }}
          </a>
          <a :href="pricesUrl" class="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.pricing') }}
          </a>
          <a :href="contactUrl" class="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.contact') }}
          </a>
          <a :href="faqUrl" class="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.faq') }}
          </a>
        </div>

        <!-- Language switcher and Logout button -->
        <div class="flex items-center space-x-4">
          <!-- Language Switcher -->
          <div class="hidden xl:flex items-center border rounded-md">
            <a :href="englishUrl"
               class="px-2 py-1 text-sm font-medium rounded-l-md hover:bg-primary-100 transition duration-150 ease-in-out"
               :class="{'bg-primary-500 text-white': currentLang === 'en', 'text-gray-700': currentLang !== 'en'}"
            >
              EN
            </a>
            <a :href="germanUrl"
               class="px-2 py-1 text-sm font-medium rounded-r-md hover:bg-primary-100 transition duration-150 ease-in-out"
               :class="{'bg-primary-500 text-white': currentLang === 'de', 'text-gray-700': currentLang !== 'de'}"
            >
              DE
            </a>
          </div>
          <a target="_blank" href="https://app.leakblock.io" class="hidden xl:block px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.login') }}
          </a>
        </div>

        <!-- Mobile menu button -->
        <div class="flex items-center xl:hidden gap-3">
          <!-- Language Switcher -->
          <div class="flex items-center border rounded-md">
            <a :href="englishUrl"
               class="px-2 py-1 text-sm font-medium rounded-l-md hover:bg-primary-100 transition duration-150 ease-in-out"
               :class="{'bg-primary-500 text-white': currentLang === 'en', 'text-gray-700': currentLang !== 'en'}"
            >
              EN
            </a>
            <a :href="germanUrl"
               class="px-2 py-1 text-sm font-medium rounded-r-md hover:bg-primary-100 transition duration-150 ease-in-out"
               :class="{'bg-primary-500 text-white': currentLang === 'de', 'text-gray-700': currentLang !== 'de'}"
            >
              DE
            </a>
          </div>
          <button
              @click="isMenuOpen = !isMenuOpen"
              class="inline-flex items-center justify-center p-2 rounded-md text-primary-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
              aria-expanded="false"
          >
            <span class="sr-only">{{ trans('leakblock.userHeader.openMainMenu') }}</span>
            <div class="relative w-7 h-7">
              <!-- Three line icon -->
              <svg
                  class="absolute transition-all duration-300 ease-in-out text-primary-500"
                  :class="isMenuOpen ? 'opacity-0 rotate-180 scale-0' : 'opacity-100 rotate-0 scale-100'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M3 6h18M3 12h18M3 18h18" />
              </svg>
              <!-- X icon -->
              <svg
                  class="absolute transition-all duration-300 ease-in-out text-primary-500"
                  :class="isMenuOpen ? 'opacity-100 rotate-0 scale-100' : 'opacity-0 -rotate-180 scale-0'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M5 19L19 5M5 5l14 14" />
              </svg>
            </div>
          </button>
        </div>
      </nav>
    </div>

    <!-- Mobile menu -->
    <div
        class="xl:hidden container transition-all duration-300 ease-in-out overflow-hidden"
        :class="isMenuOpen ? 'max-h-[400px] opacity-100' : 'max-h-0 opacity-0'"
    >
      <div class="pt-2 pb-3 space-y-1">

        <a @click="isMenuOpen = false"
        :href="homeUrl"
        class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
        {{ trans('leakblock.userHeader.home') }}
        </a>

        <a @click="isMenuOpen = false"
           :href="contentProtectionUrl"
           class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
          {{ trans('leakblock.userHeader.contentProtection') }}
        </a>

        <a @click="isMenuOpen = false"
        :href="howItWorksUrl"
        class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
        {{ trans('leakblock.userHeader.howItWorks') }}
        </a>

        <a @click="isMenuOpen = false"
           :href="pricesUrl"
           class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
          {{ trans('leakblock.userHeader.pricing') }}
        </a>

        <a @click="isMenuOpen = false"
        :href="contactUrl"
        class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
        {{ trans('leakblock.userHeader.contact') }}
        </a>

        <a @click="isMenuOpen = false"
        :href="faqUrl"
        class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
        {{ trans('leakblock.userHeader.faq') }}
        </a>

        <a target="_blank" @click="isMenuOpen = false"
        href="https://app.leakblock.io"
        class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
        {{ trans('leakblock.userHeader.login') }}
        </a>

        <!-- Language Switcher for Mobile -->
        <div class="flex items-center justify-center mt-4">
          <div class="inline-flex rounded-md shadow-sm" role="group">

            <a :href="englishUrl"
               :class="{'bg-primary-500 text-white': currentLang === 'en', 'bg-white text-gray-700': currentLang !== 'en'}"
              class="px-4 py-2 text-sm font-medium rounded-l-lg border border-gray-200 hover:bg-primary-100 focus:z-10 focus:ring-2 focus:ring-primary-500 transition duration-150 ease-in-out"
              >
              {{ trans('leakblock.userHeader.english') }}
            </a>

            <a :href="germanUrl"
               :class="{'bg-primary-500 text-white': currentLang === 'de', 'bg-white text-gray-700': currentLang !== 'de'}"
            class="px-4 py-2 text-sm font-medium rounded-r-lg border border-gray-200 hover:bg-primary-100 focus:z-10 focus:ring-2 focus:ring-primary-500 transition duration-150 ease-in-out"
            >
              {{ trans('leakblock.userHeader.deutsch') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref } from 'vue';
import { trans } from "../../js/utilities/leakblock-translations";

const props = defineProps({
  homeUrl: String,
  contentProtectionUrl: String,
  howItWorksUrl: String,
  pricesUrl: String,
  contactUrl: String,
  faqUrl: String,
  germanUrl: String,
  englishUrl: String
});

const isMenuOpen = ref(false);
const currentLang = ref(document.documentElement.lang || 'en');
</script>